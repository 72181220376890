import React from "react"

import PropTypes from "prop-types"

import Link from "../common/Link"
import "./AuthWrapper.scss"

const AuthWrapper = ({
  title,
  children,
  message,
  isLogin,
  isRegister,
  isForgotPassword,
}) => {
  return (
    <section className="auth-wrapper">
      <div className="auth-wrapper__card">
        <div className="auth-wrapper__card__heading">{title}</div>
        <div className="auth-wrapper__card__body">{children}</div>
        <div className="auth-wrapper__card__message">{message}</div>
      </div>
      {isLogin && (
        <div className="auth-wrapper__message">
          <Link to="/forgot-password">Forgot Password</Link>
        </div>
      )}
      {isRegister && (
        <div className="auth-wrapper__message">
          Already registered? <Link to="/login">Login Here</Link>
        </div>
      )}
      {isForgotPassword && (
        <div className="auth-wrapper__message">
          <Link to="/login">Back to Login</Link>
        </div>
      )}
    </section>
  )
}

AuthWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  isLogin: PropTypes.bool,
  isRegister: PropTypes.bool,
  isForgotPassword: PropTypes.bool,
  message: PropTypes.string,
  title: PropTypes.string.isRequired,
}

AuthWrapper.defaultProps = {
  isLogin: false,
  isRegister: false,
  isForgotPassword: false,
}

export default AuthWrapper
