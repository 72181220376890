import React from "react"

import { navigate } from "gatsby"

import AuthWrapper from "../components/auth/AuthWrapper"
import Button from "../components/common/Button"
import Input from "../components/common/Input"
import { AuthContext } from "../store"

const ForgotPasswordPage = () => {
  const { isAuthenticated, requestPasswordResetEmail } =
    React.useContext(AuthContext)

  const [email, setEmail] = React.useState("")

  React.useEffect(() => {
    if (isAuthenticated) {
      navigate("/")
    }
  }, [isAuthenticated])

  const handleSubmit = (e) => {
    e.preventDefault()

    requestPasswordResetEmail(email)
  }

  return (
    <AuthWrapper title="Forgot Password?" isForgotPassword>
      <p className="forgot-password-message">
        Please enter your email address below and we will send you information
        to change your password.
      </p>
      <form className="form" onSubmit={handleSubmit}>
        <Input
          type="email"
          id="email"
          label="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          isBlock
          required
        />
        <Button type="submit" color="primary" isBlock>
          Reset Password
        </Button>
      </form>
    </AuthWrapper>
  )
}

export default ForgotPasswordPage
